import React from 'react';
import './featured.scss';

export default function Featured() {
    return (

        <section className="ts-block" data-bg-pattern="assets/img/bg-pattern-dot.png"> 

            {/*Title*/}
            <div className="ts-title text-center">
                <h2>Destaques</h2>
            </div>

            {/*Carousel*/}
            <div className="owl-carousel ts-items-carousel" data-owl-items="5" data-owl-dots="1">

                {/*Item*/}
                <div className="slide">

                    <div className="card ts-item ts-card ts-item__lg">

                        {/*Ribbon*/}
                        <div className="ts-ribbon">
                            <i className="fa fa-thumbs-up"></i>
                        </div>

                        {/*Card Image*/}
                        <a href="detail-01.html" className="card-img ts-item__image" data-bg-image="assets/img/img-item-thumb-01.jpg">
                            <div className="ts-item__info-badge">
                                $350,000
                            </div>
                            <figure className="ts-item__info">
                                <h4>Big Luxury Apartment</h4>
                                <aside>
                                    <i className="fa fa-map-marker mr-2"></i>
                                    1350 Arbutus Drive
                                </aside>
                            </figure>
                        </a>

                        {/*Card Body*/}
                        <div className="card-body">
                            <div className="ts-description-lists">
                                <dl>
                                    <dt>Area</dt>
                                    <dd>325m<sup>2</sup></dd>
                                </dl>
                                <dl>
                                    <dt>Bedrooms</dt>
                                    <dd>2</dd>
                                </dl>
                                <dl>
                                    <dt>Bathrooms</dt>
                                    <dd>1</dd>
                                </dl>
                            </div>
                        </div>

                        {/*Card Footer*/}
                        <a href="detail-01.html" className="card-footer">
                            <span className="ts-btn-arrow">Detail</span>
                        </a>

                    </div>
                    {/*end ts-item*/}
                </div>
                {/*end slide*/}

                {/*Item*/}
                <div className="slide">

                    <div className="card ts-item ts-card ts-item__lg">

                        {/*Ribbon*/}
                        <div className="ts-ribbon-corner">
                            <span>Updated</span>
                        </div>

                        {/*Card Image*/}
                        <a href="detail-01.html" className="card-img ts-item__image" data-bg-image="assets/img/img-item-thumb-02.jpg">
                            <figure className="ts-item__info">
                                <h4>Cozy Design Studio</h4>
                                <aside>
                                    <i className="fa fa-map-marker mr-2"></i>
                                    4831 Worthington Drive
                                </aside>
                            </figure>
                            <div className="ts-item__info-badge">$125,000</div>
                        </a>

                        {/*Card Body*/}
                        <div className="card-body ts-item__body">
                            <div className="ts-description-lists">
                                <dl>
                                    <dt>Area</dt>
                                    <dd>325m<sup>2</sup></dd>
                                </dl>
                                <dl>
                                    <dt>Bedrooms</dt>
                                    <dd>2</dd>
                                </dl>
                                <dl>
                                    <dt>Bathrooms</dt>
                                    <dd>1</dd>
                                </dl>
                            </div>
                        </div>

                        {/*Card Footer*/}
                        <a href="detail-01.html" className="card-footer">
                            <span className="ts-btn-arrow">Detail</span>
                        </a>

                    </div>
                    {/*end ts-item ts-card*/}
                </div>
                {/*end slide*/}

                {/*Item*/}
                <div className="slide">

                    <div className="card ts-item ts-card ts-item__lg">

                        {/*Card Image*/}
                        <a href="detail-01.html" className="card-img ts-item__image" data-bg-image="assets/img/img-item-thumb-03.jpg">
                            <figure className="ts-item__info">
                                <h4>Family Villa</h4>
                                <aside>
                                    <i className="fa fa-map-marker mr-2"></i>
                                    4127 Winding Way
                                </aside>
                            </figure>
                            <div className="ts-item__info-badge">$45,900</div>
                        </a>

                        {/*Card Body*/}
                        <div className="card-body ts-item__body">
                            <div className="ts-description-lists">
                                <dl>
                                    <dt>Area</dt>
                                    <dd>325m<sup>2</sup></dd>
                                </dl>
                                <dl>
                                    <dt>Bedrooms</dt>
                                    <dd>2</dd>
                                </dl>
                                <dl>
                                    <dt>Bathrooms</dt>
                                    <dd>1</dd>
                                </dl>
                            </div>
                        </div>

                        {/*Card Footer*/}
                        <a href="detail-01.html" className="card-footer">
                            <span className="ts-btn-arrow">Detail</span>
                        </a>

                    </div>
                    {/*end ts-item ts-card*/}
                </div>
                {/*end slide*/}

                {/*Item*/}
                <div className="slide">
                    <div className="card ts-item ts-card ts-item__lg">

                        {/*Card Image*/}
                        <a href="detail-01.html" className="card-img ts-item__image" data-bg-image="assets/img/img-item-thumb-04.jpg">
                            <div className="ts-item__info-badge">
                                $860,000
                            </div>
                            <figure className="ts-item__info">
                                <h4>Traditional Restaurant</h4>
                                <aside>
                                    <i className="fa fa-map-marker mr-2"></i>
                                    1350 Arbutus Drive
                                </aside>
                            </figure>
                        </a>

                        {/*Card Body*/}
                        <div className="card-body">
                            <div className="ts-description-lists">
                                <dl>
                                    <dt>Area</dt>
                                    <dd>325m<sup>2</sup></dd>
                                </dl>
                                <dl>
                                    <dt>Bedrooms</dt>
                                    <dd>2</dd>
                                </dl>
                                <dl>
                                    <dt>Bathrooms</dt>
                                    <dd>1</dd>
                                </dl>
                            </div>
                        </div>

                        {/*Card Footer*/}
                        <a href="detail-01.html" className="card-footer">
                            <span className="ts-btn-arrow">Detail</span>
                        </a>

                    </div>
                    {/*end ts-item*/}
                </div>
                {/*end slide*/}

                {/*Item*/}
                <div className="slide">
                    <div className="card ts-item ts-card ts-item__lg">

                        {/*Card Image*/}
                        <a href="detail-01.html" className="card-img ts-item__image" data-bg-image="assets/img/img-item-thumb-05.jpg">
                            <figure className="ts-item__info">
                                <h4>White Cubes Resort</h4>
                                <aside>
                                    <i className="fa fa-map-marker mr-2"></i>
                                    4831 Worthington Drive
                                </aside>
                            </figure>
                            <div className="ts-item__info-badge">$435,000</div>
                        </a>

                        {/*Card Body*/}
                        <div className="card-body ts-item__body">
                            <div className="ts-description-lists">
                                <dl>
                                    <dt>Area</dt>
                                    <dd>325m<sup>2</sup></dd>
                                </dl>
                                <dl>
                                    <dt>Bedrooms</dt>
                                    <dd>2</dd>
                                </dl>
                                <dl>
                                    <dt>Bathrooms</dt>
                                    <dd>1</dd>
                                </dl>
                            </div>
                        </div>

                        {/*Card Footer*/}
                        <a href="detail-01.html" className="card-footer">
                            <span className="ts-btn-arrow">Detail</span>
                        </a>

                    </div>
                    {/*end ts-item ts-card*/}
                </div>
                {/*end slide*/}

                {/*Item*/}
                <div className="slide">
                    <div className="card ts-item ts-card ts-item__lg">

                        {/*Card Image*/}
                        <a href="detail-01.html" className="card-img ts-item__image" data-bg-image="assets/img/img-item-thumb-06.jpg">
                            <figure className="ts-item__info">
                                <h4>Bristol Tower Complex</h4>
                                <aside>
                                    <i className="fa fa-map-marker mr-2"></i>
                                    4127 Winding Way
                                </aside>
                            </figure>
                            <div className="ts-item__info-badge">$85,900</div>
                        </a>

                        {/*Card Body*/}
                        <div className="card-body ts-item__body">
                            <div className="ts-description-lists">
                                <dl>
                                    <dt>Area</dt>
                                    <dd>325m<sup>2</sup></dd>
                                </dl>
                                <dl>
                                    <dt>Bedrooms</dt>
                                    <dd>2</dd>
                                </dl>
                                <dl>
                                    <dt>Bathrooms</dt>
                                    <dd>1</dd>
                                </dl>
                            </div>
                        </div>

                        {/*Card Footer*/}
                        <a href="detail-01.html" className="card-footer">
                            <span className="ts-btn-arrow">Detail</span>
                        </a>

                    </div>
                    {/*end ts-item ts-card*/}
                </div>
                {/*end slide*/}

                {/*Item*/}
                <div className="slide">
                    <div className="card ts-item ts-card ts-item__lg">

                        {/*Card Image*/}
                        <a href="detail-01.html" className="card-img ts-item__image" data-bg-image="assets/img/img-item-thumb-07.jpg">
                            <figure className="ts-item__info">
                                <h4>River Apartments</h4>
                                <aside>
                                    <i className="fa fa-map-marker mr-2"></i>
                                    1350 Arbutus Drive
                                </aside>
                            </figure>
                            <div className="ts-item__info-badge">
                                $120,000
                            </div>
                        </a>

                        {/*Card Body*/}
                        <div className="card-body">
                            <div className="ts-description-lists">
                                <dl>
                                    <dt>Area</dt>
                                    <dd>325m<sup>2</sup></dd>
                                </dl>
                                <dl>
                                    <dt>Bedrooms</dt>
                                    <dd>2</dd>
                                </dl>
                                <dl>
                                    <dt>Bathrooms</dt>
                                    <dd>1</dd>
                                </dl>
                            </div>
                        </div>

                        {/*Card Footer*/}
                        <a href="detail-01.html" className="card-footer">
                            <span className="ts-btn-arrow">Detail</span>
                        </a>

                    </div>
                    {/*end ts-item*/}
                </div>
                {/*end slide*/}

                {/*Item*/}
                <div className="slide">
                    <div className="card ts-item ts-card ts-item__lg">

                        <div className="badge badge-primary">Rent</div>

                        {/*Card Image*/}
                        <a href="detail-01.html" className="card-img ts-item__image" data-bg-image="assets/img/img-item-thumb-08.jpg">
                            <figure className="ts-item__info">
                                <h4>Apartment for Rent</h4>
                                <aside>
                                    <i className="fa fa-map-marker mr-2"></i>
                                    4831 Worthington Drive
                                </aside>
                            </figure>
                            <div className="ts-item__info-badge">
                                <span>$480</span>
                                <small>/ month</small>
                            </div>
                        </a>

                        {/*Card Body*/}
                        <div className="card-body ts-item__body">
                            <div className="ts-description-lists">
                                <dl>
                                    <dt>Area</dt>
                                    <dd>325m<sup>2</sup></dd>
                                </dl>
                                <dl>
                                    <dt>Bedrooms</dt>
                                    <dd>2</dd>
                                </dl>
                                <dl>
                                    <dt>Bathrooms</dt>
                                    <dd>1</dd>
                                </dl>
                            </div>
                        </div>

                        {/*Card Footer*/}
                        <a href="detail-01.html" className="card-footer">
                            <span className="ts-btn-arrow">Detail</span>
                        </a>

                    </div>
                    {/*end ts-item ts-card*/}
                </div>


            </div>
        </section>
    )
}

