import React from 'react';
import logo from './logo.svg';
import './App.css';


import Routes from './routes';



function App() {
  return (
      <>
          <Routes/>
      </>
  );
}

export default App;
