import React from 'react';
import './partners.scss';

export default function Partners() {
    return (
        <section id="partners" className="ts-block pt-4 pb-0">
            <div className="container">

                <div className="d-block d-md-flex justify-content-between align-items-center text-center ts-partners py-3">

                    <a href="#">
                        <img src="assets/img/logo-01.png" alt=""/>
                    </a>

                    <a href="#">
                        <img src="assets/img/logo-02.png" alt=""/>
                    </a>

                    <a href="#">
                        <img src="assets/img/logo-03.png" alt=""/>
                    </a>

                    <a href="#">
                        <img src="assets/img/logo-04.png" alt=""/>
                    </a>

                    <a href="#">
                        <img src="assets/img/logo-05.png" alt=""/>
                    </a>
                </div>
            </div>
        </section>
    )
}