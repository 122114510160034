import React from 'react'

export default function Card({title, local, price, img}) {
    return (
        <div className="card ts-item ts-card">
            {/*Ribbon*/}
            <div className="ts-ribbon-corner">
                <span>Updated</span>
            </div>

            {/*Card Image*/}
            <a href="./lot" className="card-img ts-item__image" data-bg-image={img}>
                <figure className="ts-item__info">
                    <h4>{title}</h4>
                    <aside>
                        <i className="fa fa-map-marker mr-2"></i>
                        {local}
                    </aside>
                </figure>
                <div className="ts-item__info-badge">{price} €</div>
            </a>

            {/*Card Body*/}
            <div className="card-body ts-item__body">
                <div className="ts-description-lists">
                    <dl>
                        <dt>Area</dt>
                        <dd>325m<sup>2</sup></dd>
                    </dl>
                    <dl>
                        <dt>Bedrooms</dt>
                        <dd>2</dd>
                    </dl>
                    <dl>
                        <dt>Bathrooms</dt>
                        <dd>1</dd>
                    </dl>
                </div>
            </div>

            {/*Card Footer*/}
            <a href="detail-01.html" className="card-footer">
                <span className="ts-btn-arrow">Detail</span>
            </a>

        </div>
    )
}
