import React from 'react';
import './header.scss';

export default function Header() {
    return (
        <header id="ts-header" className="fixed-top">
            <nav id="ts-secondary-navigation" className="navbar p-0">
                <div className="container justify-content-end justify-content-sm-between">

                    <div className="navbar-nav d-none d-sm-block">

                        <span className="mr-4">
                                <i className="fa fa-phone-square mr-1"></i>
                                +1 123 456 789
                            </span>

                        <a href="#">
                            <i className="fa fa-envelope mr-1"></i>
                            hello@example.com
                        </a>
                    </div>


                    <div className="navbar-nav flex-row">

                        <input type="text" className="form-control p-2 border-left bg-transparent w-auto" placeholder="Search"/>

                        <select className="custom-select bg-transparent ts-text-small border-left border-right" id="language" name="language">
                            <option value="1">EN</option>
                            <option value="2">FR</option>
                            <option value="3">DE</option>
                        </select>

                    </div>
                </div>
            </nav>

            <nav id="ts-primary-navigation" className="navbar navbar-expand-md navbar-light">
                <div className="container">

                    <a className="navbar-brand" href="./">
                        <img src="assets/img/logo.png" alt=""/>
                    </a>

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarPrimary" aria-controls="navbarPrimary" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    {/*Collapsing Navigation*/}
                    <div className="collapse navbar-collapse" id="navbarPrimary">

                        {/*LEFT NAVIGATION MAIN LEVEL =====================================================================*/}
                        <ul className="navbar-nav">

                            <li className="nav-item">
                                <a className="nav-link" href="./home">Home</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="./lots">Leilões</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="./lots">Lotes</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="./home">Sobre nós</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link mr-2" href="./home">Contactos</a>
                            </li>

                        </ul>
                        {/*end Left navigation main level*/}

                        {/*RIGHT NAVIGATION MAIN LEVEL
                        =================================================================================================*/}
                        <ul className="navbar-nav ml-auto">

                            {/*LOGIN (Main level)
                            =============================================================================================*/}
                            <li className="nav-item">
                                <a className="nav-link" href="login.html">Login</a>
                            </li>

                            {/*REGISTER (Main level)
                            =============================================================================================*/}
                            <li className="nav-item">
                                <a className="nav-link text-dark" href="register.html">Register</a>
                            </li>

                            {/*SUBMIT (Main level)
                            =============================================================================================*/}
                            {/*<li className="nav-item">
                                <a className="btn btn-outline-primary btn-sm m-1 px-3" href="submit.html">
                                    <i className="fa fa-plus small mr-2"></i>
                                    Add Property
                                </a>
                            </li>*/}

                        </ul>
                        {/*end Right navigation*/}

                    </div>
                    {/*end navbar-collapse*/}
                </div>
                {/*end container*/}
            </nav>

        </header>

    )
}