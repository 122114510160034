import React from 'react';
import Header from '../components/header/Header';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import Footer from '../components/footer/Footer';

export default function LotDetail() {
    return (
        <div className="ts-page-wrapper ts-has-bokeh-bg" id="page-top">
            <Header />
            <main id="ts-main" style={{"marginTop":"114px"}}>
                <Breadcrumb />

                {/*GALLERY CAROUSEL
                    =========================================================================================================*/}
                <section id="gallery-carousel" class="">

                    <div class="owl-carousel ts-gallery-carousel ts-gallery-carousel__multi" data-owl-dots="1" data-owl-items="3" data-owl-center="1" data-owl-loop="1">

                        {/*Slide*/}
                        <div class="slide">
                            <div class="ts-image" data-bg-image="assets/img/img-detail-01.jpg">
                                <a href="assets/img/img-detail-01.jpg" class="ts-zoom popup-image"><i class="fa fa-search-plus"></i>Zoom</a>
                            </div>
                        </div>

                        {/*Slide*/}
                        <div class="slide">
                            <div class="ts-image" data-bg-image="assets/img/img-detail-02.jpg">
                                <a href="assets/img/img-detail-02.jpg" class="ts-zoom popup-image"><i class="fa fa-search-plus"></i>Zoom</a>
                            </div>
                        </div>

                        {/*Slide*/}
                        <div class="slide">
                            <div class="ts-image" data-bg-image="assets/img/img-detail-05.jpg">
                                <a href="assets/img/img-detail-03.jpg" class="ts-zoom popup-image"><i class="fa fa-search-plus"></i>Zoom</a>
                            </div>
                        </div>

                        {/*Slide*/}
                        <div class="slide">
                            <div class="ts-image" data-bg-image="assets/img/img-detail-04.jpg">
                                <a href="assets/img/img-detail-04.jpg" class="ts-zoom popup-image"><i class="fa fa-search-plus"></i>Zoom</a>
                            </div>
                        </div>

                        {/*Slide*/}
                        <div class="slide">
                            <div class="ts-image" data-bg-image="assets/img/img-detail-03.jpg">
                                <a href="assets/img/img-detail-05.jpg" class="ts-zoom popup-image"><i class="fa fa-search-plus"></i>Zoom</a>
                            </div>
                        </div>

                    </div>

                </section>

                {/*PAGE TITLE
                    =========================================================================================================*/}
                <section id="page-title" class="border-bottom ts-white-gradient">
                    <div class="container">

                        <div class="d-block d-sm-flex justify-content-between">

                            {/*Title*/}
                            <div class="ts-title mb-0">
                                <h1>Big Luxury Apartment</h1>
                                <h5 class="ts-opacity__90">
                                    <i class="fa fa-map-marker text-primary"></i>
                                    1350 Arbutus Drive
                                </h5>
                            </div>

                            {/*Price*/}
                            <h3>
                                <span class="badge badge-primary p-3 font-weight-normal ts-shadow__sm">$350,000</span>
                            </h3>

                        </div>

                    </div>
                </section>

                {/*CONTENT
                    =========================================================================================================*/}
                <section id="content">
                    <div class="container">
                        <div class="row flex-wrap-reverse">

                            {/*LEFT SIDE
                                =============================================================================================*/}
                            <div class="col-md-5 col-lg-4">

                                

                                {/*CONTACT THE AGENT
                                    =========================================================================================*/}
                                <section class="contact-the-agent">
                                    <h3>Contact the Agent</h3>

                                    <div class="ts-box">

                                        {/*Agent Image & Phone*/}
                                        <div class="ts-center__vertical mb-4">

                                            {/*Image*/}
                                            <a href="agent-detail-01.html" class="ts-circle p-5 mr-4 ts-shadow__sm" data-bg-image="assets/img/img-person-05.jpg"></a>

                                            {/*Phone contact*/}
                                            <figure class="mb-0">
                                                <h5 class="mb-0">Jane Brown</h5>
                                                <p class="mb-0">
                                                    <i class="fa fa-phone-square ts-opacity__50 mr-2"></i>
                                                    +1 602-862-1673
                                                </p>
                                            </figure>
                                        </div>

                                        {/*Agent contact form*/}
                                        <form id="form-agent" class="ts-form">

                                            {/*Name*/}
                                            <div class="form-group">
                                                <input type="text" class="form-control" id="name" name="name" placeholder="Your Name"/>
                                            </div>

                                            {/*Email*/}
                                            <div class="form-group">
                                                <input type="email" class="form-control" id="email" name="email" placeholder="Your Email"/>
                                            </div>

                                            {/*Message*/}
                                            <div class="form-group">
                                                <textarea class="form-control" id="form-contact-message" rows="3" name="message" placeholder="Hi, I want to have more information about property #156461"></textarea>
                                            </div>

                                            {/*Submit button*/}
                                            <div class="form-group clearfix mb-0">
                                                <button type="submit" class="btn btn-primary float-right" id="form-contact-submit">Send a Message
                                                </button>
                                            </div>

                                        </form>

                                    </div>
                                </section>

                                {/*ACTIONS
                                =============================================================================================*/}
                                <section id="actions">

                                    <div class="d-flex justify-content-between">

                                        <a href="#" class="btn btn-light mr-2 w-100" data-toggle="tooltip" data-placement="top" title="Add to favorites">
                                            <i class="far fa-star"></i>
                                        </a>

                                        <a href="#" class="btn btn-light mr-2 w-100" data-toggle="tooltip" data-placement="top" title="Print">
                                            <i class="fa fa-print"></i>
                                        </a>

                                        <a href="#" class="btn btn-light mr-2 w-100" data-toggle="tooltip" data-placement="top" title="Add to compare">
                                            <i class="fa fa-exchange-alt"></i>
                                        </a>

                                        <a href="#" class="btn btn-light w-100" data-toggle="tooltip" data-placement="top" title="Share property">
                                            <i class="fa fa-share-alt"></i>
                                        </a>

                                    </div>

                                </section>

                            </div>
                            {/*end col-md-4*/}

                            {/*RIGHT SIDE
                                =============================================================================================*/}
                            <div class="col-md-7 col-lg-8">

                                {/*DESCRIPTION
                                    =========================================================================================*/}
                                <section id="description">

                                    <h3>Description</h3>

                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In dictum ac augue et suscipit.
                                        Vivamus ac tellus ut massa bibendum aliquam vitae ac diam. Aenean in enim volutpat,
                                        accumsan erat non, porta massa. Nulla ac porta orci. Quisque condimentum fermentum
                                        isl, lacinia tempor erat venenatis non. Integer ut malesuada est, nec pulvinar magna.
                                        Vestibulum tincidunt malesuada mi eget mattis. Phasellus quis purus porta, auctor dolor
                                        sed, eleifend tortor. Vestibulum placerat tristique turpis, eu suscipit nulla elementum
                                        porttitor. Duis eu varius libero.
                                    </p>

                                    <dl class="ts-description-list__line mb-0 ts-column-count-2">

                                        <dt>ID:</dt>
                                        <dd class="border-bottom pb-2">#156461</dd>

                                        <dt>Category:</dt>
                                        <dd class="border-bottom pb-2">Apartments</dd>

                                        <dt>Status:</dt>
                                        <dd class="border-bottom pb-2">Sale</dd>

                                        <dt>Area:</dt>
                                        <dd class="border-bottom pb-2">248<sup>2</sup></dd>

                                        <dt>Rooms:</dt>
                                        <dd class="border-bottom pb-2">6</dd>

                                        <dt>Bathrooms:</dt>
                                        <dd class="border-bottom pb-2">2</dd>

                                        <dt>Bedrooms:</dt>
                                        <dd class="border-bottom pb-2">2</dd>

                                        <dt>Garages:</dt>
                                        <dd class="border-bottom pb-2">1</dd>

                                    </dl>

                                </section>

                                {/*DETAILS
                                    =========================================================================================*/}
                                <section id="location">
                                    <h3>Location</h3>

                                    <div class="ts-box p-0">
                                        <div class="ts-map ts-map__detail" id="ts-map-simple"
                                            data-ts-map-leaflet-provider="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}{r}.png"
                                            data-ts-map-zoom="12"
                                            data-ts-map-center-latitude="40.702411"
                                            data-ts-map-center-longitude="-73.556842"
                                            data-ts-map-scroll-wheel="1"
                                            data-ts-map-controls="0"></div>
                                        <div class="p-3 ts-text-color-light">
                                            <i class="fa fa-map-marker mr-2"></i>
                                            1496 Apple Lane
                                            San Jose, IL 62682
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {/*end col-md-8*/}

                        </div>
                        {/*end row*/}
                    </div>
                    {/*end container*/}
                </section>

                {/*SIMILAR PROPERTIES
                =============================================================================================================*/}
                <section id="similar-properties">
                    <div class="container">
                        <div class="row">

                            <div class="offset-lg-4 col-sm-12 col-lg-8">

                                <hr class="mb-5"/>

                                <h3>Similar Properties</h3>

                                {/*Item*/}
                                <div class="card ts-item ts-item__list ts-card">

                                    {/*Ribbon*/}
                                    <div class="ts-ribbon">
                                        <i class="fa fa-thumbs-up"></i>
                                    </div>

                                    {/*Card Image*/}
                                    <a href="detail-01.html" class="card-img" data-bg-image="assets/img/img-item-thumb-01.jpg"></a>

                                    {/*Card Body*/}
                                    <div class="card-body">

                                        <figure class="ts-item__info">
                                            <h4>Big Luxury Apartment</h4>
                                            <aside>
                                                <i class="fa fa-map-marker mr-2"></i>
                                                1350 Arbutus Drive
                                            </aside>
                                        </figure>

                                        <div class="ts-item__info-badge">
                                            $350,000
                                        </div>

                                        <div class="ts-description-lists">
                                            <dl>
                                                <dt>Area</dt>
                                                <dd>325m<sup>2</sup></dd>
                                            </dl>
                                            <dl>
                                                <dt>Bedrooms</dt>
                                                <dd>2</dd>
                                            </dl>
                                            <dl>
                                                <dt>Bathrooms</dt>
                                                <dd>1</dd>
                                            </dl>
                                        </div>
                                    </div>

                                    {/*Card Footer*/}
                                    <a href="detail-01.html" class="card-footer">
                                        <span class="ts-btn-arrow">Detail</span>
                                    </a>

                                </div>

                                {/*Item*/}
                                <div class="card ts-item ts-item__list ts-card">

                                    {/*Ribbon*/}
                                    <div class="ts-ribbon-corner">
                                        <span>Updated</span>
                                    </div>

                                    {/*Card Image*/}
                                    <a href="detail-01.html" class="card-img ts-item__image" data-bg-image="assets/img/img-item-thumb-02.jpg"></a>

                                    {/*Card Body*/}
                                    <div class="card-body ts-item__body">

                                        <figure class="ts-item__info">
                                            <h4>Cozy Design Studio</h4>
                                            <aside>
                                                <i class="fa fa-map-marker mr-2"></i>
                                                4831 Worthington Drive
                                            </aside>
                                        </figure>

                                        <div class="ts-item__info-badge">$125,000</div>

                                        <div class="ts-description-lists">
                                            <dl>
                                                <dt>Area</dt>
                                                <dd>325m<sup>2</sup></dd>
                                            </dl>
                                            <dl>
                                                <dt>Bedrooms</dt>
                                                <dd>2</dd>
                                            </dl>
                                            <dl>
                                                <dt>Bathrooms</dt>
                                                <dd>1</dd>
                                            </dl>
                                        </div>
                                    </div>

                                    {/*Card Footer*/}
                                    <a href="detail-01.html" class="card-footer ts-item__footer">
                                        <span class="ts-btn-arrow">Detail</span>
                                    </a>

                                </div>

                                {/*Item*/}
                                <div class="card ts-item ts-item__list ts-card">

                                    {/*Card Image*/}
                                    <a href="detail-01.html" class="card-img ts-item__image" data-bg-image="assets/img/img-item-thumb-03.jpg"></a>

                                    {/*Card Body*/}
                                    <div class="card-body ts-item__body">

                                        <figure class="ts-item__info">
                                            <h4>Family Villa</h4>
                                            <aside>
                                                <i class="fa fa-map-marker mr-2"></i>
                                                4127 Winding Way
                                            </aside>
                                        </figure>

                                        <div class="ts-item__info-badge">$45,900</div>

                                        <div class="ts-description-lists">
                                            <dl>
                                                <dt>Area</dt>
                                                <dd>325m<sup>2</sup></dd>
                                            </dl>
                                            <dl>
                                                <dt>Bedrooms</dt>
                                                <dd>2</dd>
                                            </dl>
                                            <dl>
                                                <dt>Bathrooms</dt>
                                                <dd>1</dd>
                                            </dl>
                                        </div>
                                    </div>

                                    {/*Card Footer*/}
                                    <a href="detail-01.html" class="card-footer ts-item__footer">
                                        <span class="ts-btn-arrow">Detail</span>
                                    </a>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>


                <Footer />

            </main>
            
        </div>
    )
}
