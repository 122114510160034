import React from 'react'
import Card from '../card/Card';
import './gridList.scss';

export default function GridList() {
    return (
        <div>
            <section id="items-grid">
                <div className="container">

                    {/*Row*/}
                    <div className="row">

                        {/*Items */}
                        <div className="col-sm-6 col-lg-3">
                            <Card title="Big Luxury Apartment" local="1350 Arbutus Drive" price="350,000" img="assets/img/img-item-thumb-01.jpg" />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <Card title="Cozy Design Studio" local="1350 Arbutus Drive" price="350,000" img="assets/img/img-item-thumb-02.jpg" />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <Card title="Family Villa" local="1350 Arbutus Drive" price="350,000" img="assets/img/img-item-thumb-03.jpg" />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <Card title="Big Luxury Apartment" local="1350 Arbutus Drive" price="350,000" img="assets/img/img-item-thumb-01.jpg" />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <Card title="Big Luxury Apartment" local="1350 Arbutus Drive" price="350,000" img="assets/img/img-item-thumb-02.jpg" />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <Card title="Big Luxury Apartment" local="1350 Arbutus Drive" price="350,000" img="assets/img/img-item-thumb-03.jpg" />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <Card title="Big Luxury Apartment" local="1350 Arbutus Drive" price="350,000" img="assets/img/img-item-thumb-01.jpg" />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <Card title="Big Luxury Apartment" local="1350 Arbutus Drive" price="350,000" img="assets/img/img-item-thumb-02.jpg" />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <Card title="Big Luxury Apartment" local="1350 Arbutus Drive" price="350,000" img="assets/img/img-item-thumb-03.jpg" />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <Card title="Big Luxury Apartment" local="1350 Arbutus Drive" price="350,000" img="assets/img/img-item-thumb-01.jpg" />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <Card title="Big Luxury Apartment" local="1350 Arbutus Drive" price="350,000" img="assets/img/img-item-thumb-02.jpg" />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <Card title="Big Luxury Apartment" local="1350 Arbutus Drive" price="350,000" img="assets/img/img-item-thumb-03.jpg" />
                        </div>

                    </div>
                    {/*end row*/}
                </div>
                {/*end container*/}
            </section>

            {/*PAGINATION
                =========================================================================================================*/}
            <section id="pagination">
                <div className="container">

                    {/*Pagination*/}
                    <nav aria-label="Page navigation">
                        <ul className="pagination ts-center__horizontal">
                            <li className="page-item active">
                                <a className="page-link" href="#">1</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" href="#">2</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" href="#">3</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link ts-btn-arrow" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>

                </div>
            </section>
        </div>
    )
}

