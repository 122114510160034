import React from 'react';
import './slider.scss';

export default function Slider() {
    return (
        <>
            <section id="ts-hero" className="mb-0">

                {/*Container*/}
                <div className="container py-5">
                    <div className="row ts-center__both ts-h__auto ts-min-h__60vh">

                        {/* LEFT SIDE (TITLE)
                        =====================================================================================================*/}
                        <div className="col-sm-6 col-md-8 text-white">
                            {/*Title*/}
                            <h1 className="mb-2">Find a Nice Place To Live</h1>
                            {/*Subtitle*/}
                            <h4 className="ts-opacity__50">Buy or Rent Properties at Great Prices</h4>
                            {/*More properties button*/}
                            <a href="#featured-properties" className="ts-scroll btn-dark btn-sm d-none d-md-inline-block" data-bg-color="rgba(255,255,255,.3)">Show More Properties</a>
                        </div>

                        {/* RIGHT SIDE (FORM)
                        =====================================================================================================*/}
                        <div className="col-sm-6 col-md-4">

                            {/*Form*/}
                            <form className="ts-form py-3 px-4 ts-border-radius__md ts-shadow__md" data-bg-color="rgba(255,255,255,.9)">

                                <h4 className="mb-3">Search Properties</h4>

                                {/*Keyword*/}
                                <div className="form-group my-2">
                                    <input type="text" className="form-control" id="keyword" name="keyword" placeholder="Address, City or ZIP"/>
                                </div>

                                {/*Type*/}
                                <select className="custom-select my-2" id="type" name="type">
                                    <option value="">Type</option>
                                    <option value="1">Apartment</option>
                                    <option value="2">Villa</option>
                                    <option value="3">Land</option>
                                    <option value="4">Garage</option>
                                </select>

                                {/*Status*/}
                                <select className="custom-select my-2" id="status" name="status">
                                    <option value="">Status</option>
                                    <option value="1">Rent</option>
                                    <option value="2">Sale</option>
                                </select>

                                {/*Max Price*/}
                                <select className="custom-select my-2" id="price" name="status">
                                    <option value="">Max Price</option>
                                    <option value="1">$ 5,000</option>
                                    <option value="2">$ 10,000</option>
                                    <option value="3">$ 20,000</option>
                                    <option value="4">$ 50,000</option>
                                </select>

                                {/*Submit button*/}
                                <div className="form-group my-2">
                                    <button type="submit" className="btn btn-primary w-100" id="search-btn">Search</button>
                                </div>

                            </form>
                        </div>
                        {/*end col-sm-6*/}

                    </div>
                    {/*end row*/}

                </div>
                {/*end container*/}

                <div className="ts-background ts-hero-slider ts-bg-black">
                    <div className="ts-background-image ts-opacity__50">

                        <div className="owl-carousel" data-owl-loop="1" data-owl-fadeout="1">
                            <div className="slide" data-bg-image="./assets/img/bg-building.jpg"></div>
                            <div className="slide" data-bg-image="./assets/img/bg-building.jpg"></div>
                        </div>
                    </div>
                </div>

            </section>

            <section id="category-select" class="ts-icons-select" data-bg-color="#eeeeee">

                {/*Cabins*/}
                <a href="listing-category-icons.html">
                    <aside>17</aside>
                    <img src="assets/svg/icon-cabins.svg" alt=""/>
                    <figure>
                        <h6>Cabins</h6>
                        <small>Explore</small>
                    </figure>
                </a>

                {/*Apartments*/}
                <a href="listing-category-icons.html">
                    <aside>32</aside>
                    <img src="assets/svg/icon-apartment.svg" alt=""/>
                    <figure>
                        <h6>Apartments</h6>
                        <small>Explore</small>
                    </figure>
                </a>

                {/*Houses*/}
                <a href="listing-category-icons.html">
                    <aside>86</aside>
                    <img src="assets/svg/icon-house.svg" alt=""/>
                    <figure>
                        <h6>Houses</h6>
                        <small>Explore</small>
                    </figure>
                </a>

                {/*Offices*/}
                <a href="listing-category-icons.html">
                    <aside>64</aside>
                    <img src="assets/svg/icon-offices.svg" alt=""/>
                    <figure>
                        <h6>Offices</h6>
                        <small>Explore</small>
                    </figure>
                </a>

                {/*Garages*/}
                <a href="listing-category-icons.html">
                    <aside>21</aside>
                    <img src="assets/svg/icon-garages.svg" alt=""/>
                    <figure>
                        <h6>Garages</h6>
                        <small>Explore</small>
                    </figure>
                </a>

                {/*Lands*/}
                <a href="listing-category-icons.html">
                    <aside>46</aside>
                    <img src="assets/svg/icon-land.svg" alt=""/>
                    <figure>
                        <h6>Lands</h6>
                        <small>Explore</small>
                    </figure>
                </a>

            </section>
        </>
    )
}