import React from 'react' //Como react é o import default do package react, pode ser importado sem chavetas {React}
import {Route, Switch, BrowserRouter} from 'react-router-dom'
import Home from './pages/Home'
import LotList from './pages/LotList'
import LotDetail from './pages/LotDetail'


function Routes(){
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/home" exact component={Home} />
                <Route path="/lots" exact component={LotList} />
                <Route path="/lot" exact component={LotDetail} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes