import React from 'react';
import Header from '../components/header/Header';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import GridList from '../components/gridList/GridList';
import Footer from '../components/footer/Footer';
import SearchFormTop from '../components/searchFormTop/SearchFormTop';

export default function LotList() {
    return (
        <div class="ts-page-wrapper ts-has-bokeh-bg" id="page-top">
            <Header />
            
            <main id="ts-main" style={{"marginTop":"114px"}}>
                <Breadcrumb />
                
                <section id="page-title">
                    <div className="container">
                        <div className="ts-title">
                            <h1>Search Results</h1>
                        </div>
                    </div>
                </section>

                <SearchFormTop/>

                {/*DISPLAY CONTROL
                    =========================================================================================================*/}
                <section id="display-control">
                    <div className="container clearfix">

                        {/*Display selector on the left*/}
                        <div className="float-left">
                            <a href="#" className="btn btn-outline-secondary active px-3 mr-2 mb-2 ts-btn-border-muted">
                                <i className="fa fa-th-large"></i>
                            </a>
                            <a href="#" className="btn btn-outline-secondary px-3 mb-2 ts-btn-border-muted">
                                <i className="fa fa-th-list"></i>
                            </a>
                        </div>

                        {/*Display selector on the right*/}
                        <div className="float-none float-sm-right pl-2 ts-center__vertical">
                            <label for="sorting" className="mb-0 mr-2 text-nowrap">Sort by:</label>
                            <select className="custom-select bg-transparent" id="sorting" name="sorting">
                                <option value="">Default</option>
                                <option value="1">Price lowest first</option>
                                <option value="2">Price highest first</option>
                                <option value="3">Distance</option>
                            </select>
                        </div>

                    </div>
                    {/*end container*/}
                </section>
                
                <GridList />
                
                <Footer />
            </main>
        </div>
    )
}
