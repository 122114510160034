import React from 'react';
import Header from '../components/header/Header';
import Slider from '../components/slider/Slider';
import Featured from '../components/featured/Featured';
import Partners from '../components/partners/Partners';
import Footer from '../components/footer/Footer';

export default function Home() {
    return (
        <div class="ts-page-wrapper ts-homepage" id="page-top">
            <Header />
            <Slider />
            <main id="ts-main">
                <Featured />
                <Partners />
                <Footer />
            </main>
        </div>
    )
}