import React from 'react'

export default function SearchFormTop() {
    return (
        <section id="search-form">
            <div className="container">

                <form id="form-search" className="ts-form">

                    <section className="ts-box p-0">
                        {/*PRIMARY SEARCH INPUTS
                            =========================================================================================*/}
                        <div className="form-row px-4 py-3">

                            {/*Keyword*/}
                            <div className="col-sm-12 col-md-4">
                                <div className="form-group my-2">
                                    <input type="text" className="form-control" id="keyword" name="keyword" placeholder="Address, City or ZIP"/>
                                </div>
                            </div>

                            {/*Other inputs*/}
                            <div className="col-md-8">
                                <div className="form-row">

                                    {/*Type*/}
                                    <div className="col-sm-3">
                                        <select className="custom-select my-2" id="type" name="type">
                                            <option value="">Type</option>
                                            <option value="1">Apartment</option>
                                            <option value="2">Villa</option>
                                            <option value="3">Land</option>
                                            <option value="4">Garage</option>
                                        </select>
                                    </div>

                                    {/*Status*/}
                                    <div className="col-sm-3">
                                        <select className="custom-select my-2" id="status" name="status">
                                            <option value="">Status</option>
                                            <option value="1">Rent</option>
                                            <option value="2">Sale</option>
                                        </select>
                                    </div>

                                    {/*Max Price*/}
                                    <div className="col-sm-3">
                                        <div className="input-group my-2">
                                            <input type="text" className="form-control border-right-0" id="price" placeholder="Max Price"/>
                                            <div className="input-group-append">
                                                <span className="input-group-text bg-white border-left-0">$</span>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Submit button*/}
                                    <div className="col-sm-3">
                                        <div className="form-group my-2">
                                            <button type="submit" className="btn btn-primary w-100" id="search-btn">Search
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                {/*end row*/}
                            </div>
                            {/*end col-md-8*/}

                        </div>
                        {/*end form-row*/}

                        {/*MORE OPTIONS - ADVANCED SEARCH
                            =========================================================================================*/}
                        <div className="ts-bg-light px-4 py-2 border-top">

                            {/*More Options Button*/}
                            <a href="#more-options-collapse" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="more-options-collapse">
                                <i className="fa fa-plus-circle ts-text-color-primary mr-2"></i>
                                More Options
                            </a>

                            {/*Hidden Form*/}
                            <div className="collapse" id="more-options-collapse">

                                {/*Padding*/}
                                <div className="py-4">

                                    {/*Row*/}
                                    <div className="form-row">

                                        {/*Bedrooms*/}
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label for="bedrooms">Bedrooms</label>
                                                <input type="number" className="form-control" id="bedrooms" name="bedrooms" min="0"/>
                                            </div>
                                        </div>

                                        {/*Bathrooms*/}
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label for="bathrooms">Bathrooms</label>
                                                <input type="number" className="form-control" id="bathrooms" name="bathrooms" min="0"/>
                                            </div>
                                        </div>

                                        {/*Garages*/}
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label for="garages">Garages</label>
                                                <input type="number" className="form-control" id="garages" name="garages" min="0"/>
                                            </div>
                                        </div>

                                    </div>
                                    {/*end row*/}

                                    {/*Checkboxes*/}
                                    <div id="features-checkboxes" className="w-100">
                                        <h6 className="mb-3">Features</h6>

                                        <div className="ts-column-count-3">

                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="ch_1" name="features[]" value="ch_1"/>
                                                <label className="custom-control-label" for="ch_1">Air conditioning</label>
                                            </div>

                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="ch_2" name="features[]" value="ch_2"/>
                                                <label className="custom-control-label" for="ch_2">Bedding</label>
                                            </div>

                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="ch_3" name="features[]" value="ch_3"/>
                                                <label className="custom-control-label" for="ch_3">Heating</label>
                                            </div>

                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="ch_4" name="features[]" value="ch_4"/>
                                                <label className="custom-control-label" for="ch_4">Internet</label>
                                            </div>

                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="ch_5" name="features[]" value="ch_5"/>
                                                <label className="custom-control-label" for="ch_5">Microwave</label>
                                            </div>

                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="ch_6" name="features[]" value="ch_6"/>
                                                <label className="custom-control-label" for="ch_6">Smoking allowed</label>
                                            </div>

                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="ch_7" name="features[]" value="ch_7"/>
                                                <label className="custom-control-label" for="ch_7">Terrace</label>
                                            </div>

                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="ch_8" name="features[]" value="ch_8"/>
                                                <label className="custom-control-label" for="ch_8">Balcony</label>
                                            </div>

                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="ch_9" name="features[]" value="ch_9"/>
                                                <label className="custom-control-label" for="ch_9">Iron</label>
                                            </div>

                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="ch_10" name="features[]" value="ch_10"/>
                                                <label className="custom-control-label" for="ch_10">Hi-Fi</label>
                                            </div>

                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="ch_11" name="features[]" value="ch_11"/>
                                                <label className="custom-control-label" for="ch_11">Beach</label>
                                            </div>

                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="ch_12" name="features[]" value="ch_12"/>
                                                <label className="custom-control-label" for="ch_12">Parking</label>
                                            </div>

                                        </div>
                                        {/*end ts-column-count-3*/}

                                    </div>
                                    {/*end #features-checkboxes*/}

                                </div>
                                {/*end Padding*/}
                            </div>
                            {/*end more-options-collapse*/}

                        </div>
                        {/*end ts-bg-light*/}
                    </section>

                </form>
                {/*end #form-search*/}

            </div>
            {/*end container*/}
        </section>
    )
}
